import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Code, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Image, ImageInsert } from '@ckeditor/ckeditor5-image';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
// import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';

import { AudioPlugin } from '../plugins/audioPlugin';
import { ToolbarInsertAudioPlugin } from '../plugins/toolbar-insert-audio-plugin';
import { ToolbarInsertImagePlugin } from "../plugins/toolbar-insert-image-plugin";
import { ToolbarInsertVideoPlugin } from "../plugins/toolbar-insert-video-plugin";
import { VideoPlugin } from "../plugins/video-plugin";

export class QuestionPromptEditor extends ClassicEditor {

    public static override builtinPlugins = [
        Alignment,
        AudioPlugin,
        Bold,
        Code,
        Essentials,
        Heading,
        Image,
        ImageInsert,
        Indent,
        Italic,
        Link,
        List,
        Paragraph,
        TextTransformation,
        ToolbarInsertAudioPlugin,
        ToolbarInsertImagePlugin,
        ToolbarInsertVideoPlugin,
        Underline,
        VideoPlugin,
        Autoformat,
        // Table,
        // TableToolbar,
    ];

    public static override defaultConfig: EditorConfig = {

        /**
         * Toolbar configuration.
         */
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                // 'underline',
                'code',
                'link',
                '|',
                'alignment:left',
                'alignment:center',
                'alignment:right',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                // 'insertTable',
                'customInsertImage',
                'customInsertAudio',
                'customInsertVideo',
            ]
        },

        /**
         * Alignment configuration.
         * Should apply the class `qti-align-{alignment}` to the current block.
         */
        alignment: {
            options: [
                { name: 'left', className: 'qti-align-left' },
                { name: 'center', className: 'qti-align-center' },
                { name: 'right', className: 'qti-align-right' }
            ]
        },

        htmlSupport: {
            allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },

        language: 'en',

        link: {
            decorators: {
                openInNewTab: {
                    mode: 'automatic',
                    callback: url => url ? url.startsWith('http://') || url.startsWith('https://') : false,
                    attributes: {
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    }
                }
            },
            defaultProtocol: 'http://'
        },

        list: {
            multiBlock: false
        },

        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
            ]
        },
    };

    public insertAudioTag(dataPlacementId: string, src: string, mimeType?: string) {
        const selection = this.model.document.selection.getFirstPosition()
        if (!selection) {
            this.model.change(writer => {
                writer.setSelection(writer.createPositionAt(this.model.document.getRoot()!, 'end'))
            })
        }

        this.model.change(writer => {
            const audioElement = writer.createElement('audio', {
                src,
                type: mimeType,
                width: '400',
                controls: true,
                crossorigin: 'anonymous',
                'data-placement-id': dataPlacementId
            });

            this.model.insertContent(audioElement, this.model.document.selection);
        });
    }

    public insertImageTag(src: string, alt: string, dataPlacementId: string) {
        const selection = this.model.document.selection.getFirstPosition()
        if (!selection) {
            this.model.change(writer => {
                writer.setSelection(writer.createPositionAt(this.model.document.getRoot()!, 'end'))
            })
        }
        this.execute('insertImage', {
            source: { src, alt, 'data-placement-id': dataPlacementId }
        });
    }

    public insertVideoTag(dataPlacementId: string, src: string, transcriptUrl?: string, mimeType?: string) {
        const selection = this.model.document.selection.getFirstPosition()
        if (!selection) {
            this.model.change(writer => {
                writer.setSelection(writer.createPositionAt(this.model.document.getRoot()!, 'end'))
            })
        }

        this.model.change(writer => {
            const videoElement = writer.createElement('video', {
                src,
                controls: true,
                type: mimeType,
                // Setting a default width for the video element which can be changed by the user using the resize
                // functionality. No need to set the height as it will be calculated based on the aspect ratio.
                width: '400',
                crossorigin: 'anonymous',
                'data-placement-id': dataPlacementId
            });

            if (transcriptUrl) {
                const trackElement = writer.createElement('track', {
                    src: transcriptUrl,
                    label: "English",
                    kind: "subtitles",
                    srclang: "en",
                    default: true
                });
                writer.append(trackElement, videoElement);
            }

            this.model.insertContent(videoElement, this.model.document.selection);
        });
    }
}
