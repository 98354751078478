import { DefaultEditor } from './presets/default-editor'
import { QuestionPromptEditor } from './presets/question-prompt-editor'
import { QuestionAnswerEditor } from './presets/question-answer-editor'
import { QuestionFeedbackEditor } from './presets/question-feedback-editor'
import { NarrativeAdvancedTableEditor } from './presets/narrative-advanced-table-editor'

export default {
    DefaultEditor,

    /* Question prompt */
    QuestionPromptEditor,

    /* Question answer */
    QuestionAnswerEditor,

    /* Question feedback */
    QuestionFeedbackEditor,

    /* Narrative authoring */
    NarrativeAdvancedTableEditor
}
